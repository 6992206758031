<template>
    <div id="pageList" class="genCodeList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">表名：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入表名模糊匹配查询..." v-model="pageList.queryParam.tbName" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">模块名称：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入模块名称模糊匹配查询..." v-model="pageList.queryParam.modelName" clearable/>
                    </el-col>
                </el-row>
            </template>

            <template v-slot:tbCols>
                <el-table-column prop="F_MODEL_NAME" label="模块名称"/>
                <el-table-column prop="F_MODEL_CAPTION" label="模块描述"/>
                <el-table-column prop="F_TABLE_NAME" label="主表名称"/>
                <el-table-column prop="F_CARD_COL" label="表单列数"/>
                <el-table-column prop="F_BILL_FLAG" label="是否单据" :formatter="formatterBillFlag"
                                 :filters="[{ text: '卡片', value: '0' }, { text: '单据', value: '1' }]" :filter-method="filterBillFlag"/>
            </template>
        </ListEngine>

    </div>
</template>

<script>
    import GenCodeListHelper from "@/views/genCode/GenCodeListHelper.js";

    export default GenCodeListHelper;
</script>

<style scoped>
    .genCodeList{width: 100%;}
</style>
